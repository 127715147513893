import { useEffect, useState } from "react";

import { GrowthbookIntegration } from "../services/growthbook.ts";
import { checkIsDesktop } from "../utils/checkIsDesktop.ts";
import { checkUuidVal } from "../reducers/analytics.ts";
import { initFlagsmith } from "../services/flagsmith.ts";
import { logErr } from "../utils/logs.ts";
import { selectAnalyticsUuid } from "../selectors/analytics.ts";
import useFetchGeoLocation from "./useFetchGeoLocation.ts";
import useRemoteConfig from "./useRemoteConfig.ts";
import { useSelector } from "react-redux";
import { getFlagProviderLocalOverride } from "../services/flags.ts";

export default function useInitialiseFlagsProvider(
  isExperimentUser: boolean,
  disableUpFrontPayment: boolean,
  swimlane: string | undefined,
): boolean {
  const [flagsProviderReady, setFlagsProviderReady] = useState(false);
  const analyticsUuid = useSelector(selectAnalyticsUuid);
  const appConfig = useRemoteConfig();
  const { data: geoData, isError: geoError } = useFetchGeoLocation();
  const countryCode = geoData?.location?.countryCode;
  const currencyCode = geoData?.meta?.currency;
  const params = new URLSearchParams(window.location.search);
  const landingPageVariant = params.get("lpe");

  useEffect(() => {
    const prvdr =
      getFlagProviderLocalOverride() ||
      appConfig.generalSettings?.FLAGS_PROVIDER;

    const initialiseFlagsProvider = async () => {
      const isDesktop = checkIsDesktop();
      const traits = {
        "web-user": true,
        ...(countryCode ? { "country-code": countryCode } : {}),
        ...(currencyCode ? { "currency-code": currencyCode } : {}),
        ...(isExperimentUser ? { "experiment-user": true } : {}),
        ...(isDesktop && prvdr === "flagsmith" ? { is_desktop: true } : {}), // underscore
        ...(isDesktop && prvdr === "growthbook" ? { "is-desktop": true } : {}), // dash
        ...(disableUpFrontPayment ? { "up-front-payment-disabled": true } : {}),
        ...(swimlane ? { swimlane } : {}),
        ...(landingPageVariant != null
          ? { "landing-page-variant": landingPageVariant }
          : {}),
      };

      checkUuidVal(analyticsUuid, "useInitialiseFlagsmith");

      try {
        if (prvdr === "growthbook") {
          await GrowthbookIntegration.initGrowthbook(analyticsUuid, traits);
        } else {
          await initFlagsmith(analyticsUuid, traits);
        }
      } catch (err) {
        logErr(`Failed to initialise flags provider - ${prvdr}`, err);
      }
      setFlagsProviderReady(true);
    };

    if (appConfig.isFetched && (geoData || geoError)) initialiseFlagsProvider();
  }, [
    analyticsUuid,
    countryCode,
    currencyCode,
    disableUpFrontPayment,
    geoData,
    geoError,
    isExperimentUser,
    swimlane,
    landingPageVariant,
    appConfig.isFetched,
    appConfig.generalSettings,
  ]);

  return flagsProviderReady;
}
